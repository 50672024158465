import React, { useState } from 'react'
import { Form, Input, Button, Layout, message, Typography, Row, Col } from 'antd'
import { AiOutlineMail, AiOutlineLock } from 'react-icons/ai'
import { auth } from '../../backend'
import Dashboard from '../../images/dashboard.svg'

const { Title, Text } = Typography

const ForgotPassword = () => {
	const [loading, setLoading] = useState(false)

	const onFinish = values => {
		setLoading(true)
		sendEmail(values)
	}

	const sendEmail = values => {
		auth
			.sendPasswordResetEmail(values.email.trim(), { url: 'https://app.locshake.com' })
			.then(function () {
				setLoading(false)
				message.success("Email envoyé ! N'oubliez pas de vérifier vos spams ;)")
			})
			.catch(function (error) {
				setLoading(false)
				console.log(error)
				message.error("Votre identifiant n'est pas correct.")
			})
	}

	return (
		<Layout>
			<Row style={{ height: '100vh' }}>
				<Col xs={24} lg={12} style={{ display: 'flex' }}>
					<div style={{ backgroundColor: 'white', flex: 1 }}>
						<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%', padding: 32 }}>
							<div style={{ display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between', maxWidth: 500 }}>
								<div className="primary-color " style={{ fontSize: 21, fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
									<AiOutlineLock style={{ marginRight: 4 }} /> LocShake
								</div>
								<div>
									<Title level={2}>Mot de passe oublié</Title>
									<Text type="secondary">Renseignez votre adresse de connexion pour recevoir l'email de réinitialisation de mot passe.</Text>
									<Form layout="vertical" name="normal_login" initialValues={{ remember: true }} onFinish={onFinish} style={{ marginTop: 32 }}>
										<Form.Item label="Adresse email" name="email" rules={[{ required: true, message: "Où est passé l'email ?" }]}>
											<Input size="large" prefix={<AiOutlineMail />} placeholder="Email" />
										</Form.Item>
										<Form.Item>
											<Button size="large" loading={loading} type="primary" htmlType="submit" style={{ width: '100%', marginTop: 32 }}>
												Envoyer
											</Button>
										</Form.Item>
									</Form>
								</div>
								<div style={{ display: 'flex', justifyContent: 'space-between', fontSize: 12 }}>
									<Text type="secondary">© 2022 Atelier Business Food. Tous droits réservés.</Text>
								</div>
							</div>
						</div>
					</div>
				</Col>
				<Col xs={0} lg={12}>
					<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
						<div style={{ padding: 32, maxWidth: 500 }}>
							<img style={{ width: '100%', height: '100%' }} src={Dashboard} />
							<Title style={{ textAlign: 'center', marginTop: 32 }} level={4}>
								Gérez vos cautions très simplement.
							</Title>
						</div>
					</div>
				</Col>
			</Row>
		</Layout>
	)
}

export default ForgotPassword
