import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { firestore } from '../../backend'

export const saveTemplate = createAsyncThunk('templates/saveTemplate', async template => {
	const templateId = template.id || firestore.collection('templates').doc().id

	if (template.id) {
		return firestore.collection('templates').doc(templateId).update(template.data)
	} else {
		return firestore.collection('templates').doc(templateId).set(template.data)
	}
})

export const fetchTemplates = userId => dispatch => {
	return firestore
		.collection('templates')
		.where('userId', '==', userId)
		.onSnapshot(
			querySnapshot => {
				const docs = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
				dispatch(setTemplates(docs))
			},
			error => {
				console.log(error)
			}
		)
}

export const templatesSlice = createSlice({
	name: 'templates',
	initialState: {
		value: [],
		status: 'idle',
		error: null,
	},
	reducers: {
		setTemplates: (state, action) => {
			state.value = action.payload
		},
		setTemplatesStatus: (state, action) => {
			state.status = action.payload
		},
	},
	extraReducers: {
		[saveTemplate.pending]: (state, action) => {
			state.status = 'loading'
		},
		[saveTemplate.fulfilled]: (state, action) => {
			state.status = 'succeeded'
		},
		[saveTemplate.rejected]: (state, action) => {
			state.status = 'failed'
			state.error = action.error.message
		},
	},
})

export const getTemplates = state => state.templates.value
export const getTemplatesStatus = state => state.templates.status

export const { setTemplates, setTemplatesStatus } = templatesSlice.actions

export default templatesSlice.reducer
