import { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Row, Col, Steps, Input, message, Button, Divider, Result, Typography, Spin } from 'antd'
import PhoneInput from 'react-phone-number-input/input'
import StripeUI from '../Utils/StripeUI'
import { handleChange, mappingCurr } from '../../utils'
import { httpDomain, firestore, isTest } from '../../backend'
import Success from '../../images/success.svg'
import LoadingImage from '../../images/loading.svg'

const { TextArea } = Input
const { Step } = Steps
const { Text } = Typography

const steps = [
	{
		title: 'Prestation',
	},
	{
		title: 'Client',
	},
	{
		title: 'Carte Bancaire',
	},
]

const CustomerPaymentProduct = () => {
	const [loading, setLoading] = useState(false)
	const [payment, setPayment] = useState({ amount: 0 })
	const [customer, setCustomer] = useState({})
	const [currentStep, setCurrentStep] = useState(0)
	const [stripeData, setStripeData] = useState(null)
	const [isSuccess, setIsSuccess] = useState(false)
	const [isBlocked, setIsBlocked] = useState(false)
	const [isProductInactive, setIsProductInactive] = useState(false)
	const [data, setData] = useState({})

	const { productId } = useParams()

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true)
			try {
				const req = await fetch(`${httpDomain}/getDataFromProductId`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({ data: { productId } }),
				})
				const { result } = await req.json()

				if (result.error) {
					setLoading(false)
					return message.error('Oups ! Une erreur est survenue...')
				}

				if (result.isBlocked) {
					setLoading(false)
					setIsBlocked(true)
					return message.error('Ce service est momentanément indisponible.')
				}

				if (!result.isActive) {
					setLoading(false)
					setIsProductInactive(true)
					return message.error("Malheureusement, ce lien n'existe plus.")
				}

				setLoading(false)
				setData(result)
			} catch (error) {
				setLoading(false)
				return message.error('Oups ! Une erreur est survenue...')
			}
		}
		fetchData()
	}, [])

	useEffect(() => {
		data.amount && setPayment(_payment => ({ ..._payment, amount: data.amount, name: data.product_name, productId }))
	}, [data.amount])

	const nextStep = async () => {
		const req = await validateStep()
		req && setCurrentStep(currentStep + 1)
	}

	const prevStep = () => {
		setCurrentStep(currentStep - 1)
	}

	const renderFooter = () => {
		if (currentStep === steps.length - 1) {
			return null
		}
		return (
			<div>
				{currentStep > 0 && (
					<Button disabled={loading} style={{ margin: '0 8px' }} onClick={prevStep}>
						Précédent
					</Button>
				)}
				{currentStep < steps.length - 1 && (
					<Button type="primary" onClick={nextStep} loading={loading}>
						Suivant
					</Button>
				)}
			</div>
		)
	}

	const validateStep = async () => {
		if (currentStep === 1) {
			if (!customer.email) {
				message.warning('Il manque le champs "Email".')
				return false
			}

			if (!customer.firstName) {
				message.warning('Il manque le champs "Prénom".')
				return false
			}

			if (!customer.lastName) {
				message.warning('Il manque le champs "Nom".')
				return false
			}

			setLoading(true)
			const dbPaymentId = firestore.collection('payments').doc().id
			const dbCustomerId = firestore.collection('customers').doc().id
			const req = await fetch(`${httpDomain}/createStripeIntent`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					data: {
						amount: payment.amount,
						email: customer.email,
						description: `${customer.firstName || ''} ${customer.lastName || ''}`,
						stripeAccountId: data.stripeAccountId,
						paymentId: dbPaymentId,
						currency: data.currency,
						isTest,
					},
				}),
			})

			const { result } = await req.json()

			const { customerId, clientSecret, paymentIntentId } = result

			setStripeData({ customerId, clientSecret, paymentIntentId, dbPaymentId, dbCustomerId })
			setLoading(false)
		}

		return true
	}

	const handleSave = async () => {
		try {
			const req = await fetch(`${httpDomain}/saveCustomerAndPayment`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					data: {
						payment,
						customer,
						userId: data.userId,
						customerId: stripeData.dbCustomerId,
						paymentId: stripeData.dbPaymentId,
						stripeCustomerId: stripeData.customerId,
						paymentIntentId: stripeData.paymentIntentId,
						clientSecret: stripeData.clientSecret,
					},
				}),
			})

			const { result } = await req.json()
			if (result.error) {
				setCurrentStep(0)
				return message.error('Oups ! Une erreur est survenue...')
			}
			setIsSuccess(true)
			message.success('Bravo 🎉 ! Votre caution a bien été créée.')
		} catch (error) {
			message.error('Oups ! Une erreur est survenue...')
			setCurrentStep(0)
		}
	}

	return (
		<Row style={{ height: '100vh', overflow: 'scroll', WebkitOverflowScrolling: 'touch', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgb(240, 242, 245)', padding: 32 }}>
			<Col sm={{ span: 0 }} lg={{ span: 4 }}></Col>
			<Col lg={{ span: 16 }}>
				<div style={{ marginBottom: 16, display: 'flex', justifyContent: 'center' }}>
					<div>
						<Text type="secondary" style={{ display: 'block', textAlign: 'center' }}>
							Prise de caution
						</Text>
						<div style={{ display: 'block', textAlign: 'center', fontSize: 30, fontWeight: 'bold' }}>{data.name}</div>
					</div>
				</div>
				<div style={{ backgroundColor: 'white', padding: 32 }}>
					{isSuccess ? (
						<Result icon={<img width="60%" src={Success} />} status="success" title="Opération réussie !" subTitle="Merci ❤" />
					) : data.stripeAccountId ? (
						<>
							<Steps size="small" current={currentStep} style={{ marginBottom: 32 }}>
								{steps.map(item => (
									<Step key={item.title} title={item.title} />
								))}
							</Steps>

							{currentStep === 0 && (
								<>
									<div style={{ display: 'flex', marginBottom: 16 }}>
										<div style={{ flex: 1 }}>
											<Text type="secondary" style={{ fontSize: 12, marginBottom: 4 }}>
												Intitulé
											</Text>
											<div>{data.product_name}</div>
										</div>
									</div>
									<div style={{ display: 'flex', marginBottom: 16 }}>
										<div style={{ flex: 1 }}>
											<Text type="secondary" style={{ fontSize: 12, marginBottom: 4 }}>
												Montant
											</Text>
											<div>{`${data.amount / 100} ${mappingCurr[data.currency || '€']}`}</div>
										</div>
									</div>
									<div style={{ display: 'flex', marginBottom: 16 }}>
										<div style={{ flex: 1 }}>
											<Text type="secondary" style={{ marginBottom: 4, fontSize: 12 }}>
												Commentaires
											</Text>
											<TextArea rows={2} placeholder="Commentaires" style={{ width: '100%' }} value={payment.comments} onChange={handleChange('input', 'comments', payment, setPayment)} />
										</div>
									</div>
								</>
							)}
							{currentStep === 1 && (
								<>
									<div style={{ display: 'flex', marginBottom: 16 }}>
										<div style={{ flex: 1 }}>
											<div style={{ marginBottom: 8 }}>Prénom</div>
											<Input placeholder="Prénom" style={{ width: '100%' }} value={customer.firstName} onChange={handleChange('input', 'firstName', customer, setCustomer)} />
										</div>
										<div style={{ flex: 1, marginLeft: 8 }}>
											<div style={{ marginBottom: 8 }}>Nom</div>
											<Input placeholder="Nom" style={{ width: '100%' }} value={customer.lastName} onChange={handleChange('input', 'lastName', customer, setCustomer)} />
										</div>
									</div>
									<div style={{ display: 'flex', marginBottom: 16 }}>
										<div style={{ flex: 1 }}>
											<div style={{ marginBottom: 8 }}>Email</div>
											<Input placeholder="Email" style={{ width: '100%' }} value={customer.email} onChange={handleChange('input', 'email', customer, setCustomer)} />
										</div>
										<div style={{ flex: 1, marginLeft: 8 }}>
											<div style={{ marginBottom: 8 }}>Téléphone</div>
											<PhoneInput placeholder="06 11 22 33 44" className="ant-input" country="FR" value={customer.phoneNumber} onChange={handleChange('value', 'phoneNumber', customer, setCustomer)} />
										</div>
									</div>
								</>
							)}
							{currentStep === 2 && <StripeUI CGV={data.cgv_link} stripeAccountId={data.stripeAccountId} stripeData={stripeData} prevStep={prevStep} handleSave={handleSave} />}
							<Divider />
							{renderFooter()}
						</>
					) : isBlocked || isProductInactive ? (
						<div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: 32 }}>
							<div style={{ textAlign: 'center', fontSize: 24, fontWeight: 'bold' }}>
								<span style={{ marginLeft: 8 }}>Service indisponible</span>
							</div>
						</div>
					) : (
						<div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: 32 }}>
							<div style={{ textAlign: 'center', fontSize: 24, fontWeight: 'bold' }}>
								<Spin />
								<span style={{ marginLeft: 8 }}>Chargement...</span>
							</div>
							<div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
								<img src={LoadingImage} style={{ width: '100%', height: 300, objectFit: 'contain' }} />
							</div>
						</div>
					)}
				</div>
			</Col>
			<Col sm={{ span: 0 }} lg={{ span: 4 }}></Col>
		</Row>
	)
}

export default CustomerPaymentProduct
