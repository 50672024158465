import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { firestore } from '../../backend'

export const saveCustomer = createAsyncThunk('customers/saveCustomer', async customer => {
	const customerId = customer.id || firestore.collection('customers').doc().id

	if (customer.id) {
		return firestore.collection('customers').doc(customerId).update(customer.data)
	} else {
		return firestore.collection('customers').doc(customerId).set(customer.data)
	}
})

/*export const fetchCustomers = createAsyncThunk('customers/fetchCustomers', async (userId, { dispatch }) => {
	firestore
		.collection('customers')
		.where('userId', '==', userId)
		.onSnapshot(
			querySnapshot => {
				const docs = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
				dispatch(setCustomers(docs))
			},
			error => {
				console.log(error)
			}
		)
})*/

export const fetchCustomers = userId => dispatch => {
	dispatch(setCustomersStatus('loading'))
	return firestore
		.collection('customers')
		.where('userId', '==', userId)
		.onSnapshot(
			querySnapshot => {
				dispatch(setCustomersStatus('idle'))
				const docs = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
				dispatch(setCustomers(docs))
			},
			error => {
				console.log(error)
			}
		)
}

export const customersSlice = createSlice({
	name: 'customers',
	initialState: {
		value: [],
		status: 'idle',
		error: null,
	},
	reducers: {
		setCustomers: (state, action) => {
			state.value = action.payload
		},
		setCustomersStatus: (state, action) => {
			state.status = action.payload
		},
	},
	extraReducers: {
		[saveCustomer.pending]: (state, action) => {
			state.status = 'loading'
		},
		[saveCustomer.fulfilled]: (state, action) => {
			state.status = 'succeeded'
		},
		[saveCustomer.rejected]: (state, action) => {
			state.status = 'failed'
			state.error = action.error.message
		},
	},
})

export const getCustomers = state => state.customers.value
export const getCustomersStatus = state => state.customers.status

export const { setCustomers, setCustomersStatus } = customersSlice.actions

export default customersSlice.reducer
