import { configureStore } from '@reduxjs/toolkit'
import activeUserReducer from './reducers/activeUserReducer'
import paymentsReducer from './reducers/paymentsReducer'
import customersReducer from './reducers/customersReducer'
import templatesReducer from './reducers/templatesReducer'
import mappingReducer from './reducers/mappingReducer'
import productsReducer from './reducers/productsReducer'

export default configureStore({
	reducer: {
		activeUser: activeUserReducer,
		payments: paymentsReducer,
		customers: customersReducer,
		templates: templatesReducer,
		mapping: mappingReducer,
		products: productsReducer,
	},
})
