import { useState, useEffect } from 'react'
import { useParams, useLocation } from 'react-router-dom'
import { Row, Col, Steps, Input, InputNumber, message, Button, Divider, Result, Typography, Spin } from 'antd'
import PhoneInput from 'react-phone-number-input/input'
import StripeUI from '../Utils/StripeUI'
import { handleChange, mappingCurr } from '../../utils'
import { httpDomain, firestore, isTest } from '../../backend'
import Success from '../../images/success.svg'
import LoadingImage from '../../images/loading.svg'

const { TextArea } = Input
const { Step } = Steps
const { Text } = Typography

const steps = [
	{
		title: 'Prestation',
	},
	{
		title: 'Client',
	},
	{
		title: 'Carte Bancaire',
	},
]

const CustomerPaymentGlobal = () => {
	const [loading, setLoading] = useState(false)
	const [payment, setPayment] = useState({ amount: 0 })
	const [customer, setCustomer] = useState({})
	const [currentStep, setCurrentStep] = useState(0)
	const [stripeAccountId, setStripeAccountId] = useState(null)
	const [name, setName] = useState(null)
	const [stripeData, setStripeData] = useState(null)
	const [isSuccess, setIsSuccess] = useState(false)
	const [isBlocked, setIsBlocked] = useState(false)
	const [CGV, setCGV] = useState()
	const [currency, setCurrency] = useState('eur')

	const { userId } = useParams()
	const useQuery = () => new URLSearchParams(useLocation().search)
	const amount = 100 * useQuery().get('amount')

	useEffect(() => {
		const fetchData = async () => {
			setLoading(true)
			try {
				const req = await fetch(`${httpDomain}/getPublicStripeAccountId`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({ data: { userId } }),
				})
				const { result } = await req.json()

				if (result.error) {
					setLoading(false)
					return message.error('Oups ! Une erreur est survenue...')
				}

				if (result.isBlocked) {
					setLoading(false)
					setIsBlocked(true)
					return message.error('Ce service est momentanément indisponible.')
				}

				setLoading(false)
				setStripeAccountId(result.stripeAccountId)
				setName(result.name)
				result.currency && setCurrency(result.currency)
				result.cgv_link && setCGV(result.cgv_link)
			} catch (error) {
				setLoading(false)
				return message.error('Oups ! Une erreur est survenue...')
			}
		}
		fetchData()
	}, [])

	useEffect(() => {
		amount && setPayment(_payment => ({ ..._payment, amount }))
	}, [amount])

	const nextStep = async () => {
		const req = await validateStep()
		req && setCurrentStep(currentStep + 1)
	}

	const prevStep = () => {
		setCurrentStep(currentStep - 1)
	}

	const renderFooter = () => {
		if (currentStep === steps.length - 1) {
			return null
		}
		return (
			<div>
				{currentStep > 0 && (
					<Button disabled={loading} style={{ margin: '0 8px' }} onClick={prevStep}>
						Précédent
					</Button>
				)}
				{currentStep < steps.length - 1 && (
					<Button type="primary" onClick={nextStep} loading={loading}>
						Suivant
					</Button>
				)}
			</div>
		)
	}

	const validateStep = async () => {
		if (currentStep === 0) {
			if (!payment.name) {
				message.warning('Il manque le champs "Intitulé".')
				return false
			}
			if (!payment.amount || payment.amount === 0) {
				message.warning('Le champ "Montant" doit être supérieur à zéro.')
				return false
			}
		}

		if (currentStep === 1) {
			if (!customer.email) {
				message.warning('Il manque le champs "Email".')
				return false
			}

			if (!customer.firstName) {
				message.warning('Il manque le champs "Prénom".')
				return false
			}

			if (!customer.lastName) {
				message.warning('Il manque le champs "Nom".')
				return false
			}

			setLoading(true)
			const dbPaymentId = firestore.collection('payments').doc().id
			const dbCustomerId = firestore.collection('customers').doc().id
			const req = await fetch(`${httpDomain}/createStripeIntent`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					data: {
						amount: payment.amount,
						email: customer.email,
						description: `${customer.firstName || ''} ${customer.lastName || ''}`,
						stripeAccountId: stripeAccountId,
						paymentId: dbPaymentId,
						currency: currency,
						isTest,
					},
				}),
			})

			const { result } = await req.json()

			const { customerId, clientSecret, paymentIntentId } = result

			setStripeData({ customerId, clientSecret, paymentIntentId, dbPaymentId, dbCustomerId })
			setLoading(false)
		}

		return true
	}

	const handleSave = async () => {
		try {
			const req = await fetch(`${httpDomain}/saveCustomerAndPayment`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({
					data: {
						payment,
						customer,
						userId,
						customerId: stripeData.dbCustomerId,
						paymentId: stripeData.dbPaymentId,
						stripeCustomerId: stripeData.customerId,
						paymentIntentId: stripeData.paymentIntentId,
						clientSecret: stripeData.clientSecret,
					},
				}),
			})

			const { result } = await req.json()
			if (result.error) {
				setCurrentStep(0)
				return message.error('Oups ! Une erreur est survenue...')
			}
			setIsSuccess(true)
			message.success('Bravo 🎉 ! Votre caution a bien été créée.')
		} catch (error) {
			message.error('Oups ! Une erreur est survenue...')
			setCurrentStep(0)
		}
	}

	return (
		<Row style={{ height: '100vh', overflow: 'scroll', WebkitOverflowScrolling: 'touch', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgb(240, 242, 245)', padding: 32 }}>
			<Col sm={{ span: 0 }} lg={{ span: 4 }}></Col>
			<Col lg={{ span: 16 }}>
				<div style={{ marginBottom: 16, display: 'flex', justifyContent: 'center' }}>
					<div>
						<Text type="secondary" style={{ display: 'block', textAlign: 'center' }}>
							Prise de caution
						</Text>
						<div style={{ display: 'block', textAlign: 'center', fontSize: 30, fontWeight: 'bold' }}>{name}</div>
					</div>
				</div>
				<div style={{ backgroundColor: 'white', padding: 32 }}>
					{isSuccess ? (
						<Result icon={<img width="60%" src={Success} />} status="success" title="Opération réussie !" subTitle="Merci ❤" />
					) : stripeAccountId ? (
						<>
							<Steps size="small" current={currentStep} style={{ marginBottom: 32 }}>
								{steps.map(item => (
									<Step key={item.title} title={item.title} />
								))}
							</Steps>

							{currentStep === 0 && (
								<>
									<div style={{ display: 'flex', marginBottom: 16 }}>
										<div style={{ flex: 1 }}>
											<div style={{ marginBottom: 8 }}>Intitulé *</div>
											<Input placeholder="Description de votre caution" style={{ width: '100%' }} value={payment.name} onChange={handleChange('input', 'name', payment, setPayment)} />
										</div>
									</div>
									<div style={{ display: 'flex', marginBottom: 16 }}>
										<div style={{ flex: 1 }}>
											<div style={{ marginBottom: 8 }}>Montant *</div>
											<InputNumber disabled={amount} formatter={value => `${value} ${mappingCurr[currency]}`} style={{ width: '100%' }} value={payment.amount / 100} onChange={handleChange('number', 'amount', payment, setPayment)} />
										</div>
									</div>
									<div style={{ display: 'flex', marginBottom: 16 }}>
										<div style={{ flex: 1 }}>
											<div style={{ marginBottom: 8 }}>Commentaires</div>
											<TextArea rows={2} placeholder="Commentaires" style={{ width: '100%' }} value={payment.comments} onChange={handleChange('input', 'comments', payment, setPayment)} />
										</div>
									</div>
								</>
							)}
							{currentStep === 1 && (
								<>
									<div style={{ display: 'flex', marginBottom: 16 }}>
										<div style={{ flex: 1 }}>
											<div style={{ marginBottom: 8 }}>Prénom</div>
											<Input placeholder="Prénom" style={{ width: '100%' }} value={customer.firstName} onChange={handleChange('input', 'firstName', customer, setCustomer)} />
										</div>
										<div style={{ flex: 1, marginLeft: 8 }}>
											<div style={{ marginBottom: 8 }}>Nom</div>
											<Input placeholder="Nom" style={{ width: '100%' }} value={customer.lastName} onChange={handleChange('input', 'lastName', customer, setCustomer)} />
										</div>
									</div>
									<div style={{ display: 'flex', marginBottom: 16 }}>
										<div style={{ flex: 1 }}>
											<div style={{ marginBottom: 8 }}>Email</div>
											<Input placeholder="Email" style={{ width: '100%' }} value={customer.email} onChange={handleChange('input', 'email', customer, setCustomer)} />
										</div>
										<div style={{ flex: 1, marginLeft: 8 }}>
											<div style={{ marginBottom: 8 }}>Téléphone</div>
											<PhoneInput placeholder="06 11 22 33 44" className="ant-input" country="FR" value={customer.phoneNumber} onChange={handleChange('value', 'phoneNumber', customer, setCustomer)} />
										</div>
									</div>
								</>
							)}
							{currentStep === 2 && <StripeUI CGV={CGV} stripeAccountId={stripeAccountId} stripeData={stripeData} prevStep={prevStep} handleSave={handleSave} />}
							<Divider />
							{renderFooter()}
						</>
					) : isBlocked ? (
						<div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: 32 }}>
							<div style={{ textAlign: 'center', fontSize: 24, fontWeight: 'bold' }}>
								<span style={{ marginLeft: 8 }}>Service indisponible</span>
							</div>
						</div>
					) : (
						<div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: 32 }}>
							<div style={{ textAlign: 'center', fontSize: 24, fontWeight: 'bold' }}>
								<Spin />
								<span style={{ marginLeft: 8 }}>Chargement...</span>
							</div>
							<div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
								<img src={LoadingImage} style={{ width: '100%', height: 300, objectFit: 'contain' }} />
							</div>
						</div>
					)}
				</div>
			</Col>
			<Col sm={{ span: 0 }} lg={{ span: 4 }}></Col>
		</Row>
	)
}

export default CustomerPaymentGlobal
