import { useState, useMemo } from 'react'
import { Button, message, Divider } from 'antd'
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { STRIPE_KEY } from '../../backend'

const StripeUI = ({ stripeData, prevStep, handleSave, CGV }) => {
	const [loading, setLoading] = useState(false)

	const { clientSecret } = stripeData

	const stripe = useStripe()
	const elements = useElements()

	const handleSubmit = async event => {
		event.preventDefault()

		setLoading(true)

		if (!stripe || !elements) {
			return
		}

		try {
			const cardElement = elements.getElement(CardElement)

			const result = await stripe.confirmCardPayment(clientSecret, {
				payment_method: {
					card: cardElement,
				},
				setup_future_usage: 'off_session',
			})

			if (result.error) {
				setLoading(false)
				console.log('[error]', result.error)
				message.error(result.error.message)
			} else {
				await handleSave()
			}
		} catch (error) {
			console.log(error)
			setLoading(false)
			message.error('Oups ! Une erreur est survenue...')
		}
	}

	return (
		<>
			<form onSubmit={handleSubmit}>
				<div style={{ marginBottom: 8, fontWeight: 'bold' }}>Votre carte bancaire</div>
				<div style={{ marginBottom: 16 }}>
					<CardElement
						className="ant-input"
						options={{
							iconStyle: 'solid',
							hidePostalCode: true,
							style: {
								base: {
									iconColor: 'lightgray',
									color: 'rgba(0,0,0,0.65)',
									fontWeight: 500,
									fontSize: '18px',
									fontSmoothing: 'antialiased',
									':-webkit-autofill': {
										color: '#fce883',
									},
									'::placeholder': {
										color: 'lightgray',
									},
								},
								invalid: {
									iconColor: '#db4252cc',
									color: '#db4252cc',
								},
							},
						}}
					/>
				</div>
				<Divider />
				{CGV && (
					<div style={{ fontSize: 12, textAlign: 'right', marginBottom: 16 }}>
						En cliquant sur Enregistrer, vous acceptez les{' '}
						<a href={CGV} target="_blank">
							Conditions Générales de Ventes
						</a>
					</div>
				)}
				<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
					<Button disabled={!stripe || loading} style={{ marginRight: 16 }} onClick={prevStep}>
						Précédent
					</Button>
					<Button type="primary" htmlType="submit" disabled={!stripe} loading={loading}>
						Enregistrer
					</Button>
				</div>
			</form>
		</>
	)
}

const StripeWrapper = ({ stripeAccountId, stripeData, prevStep, handleSave, CGV }) => {
	const stripePromise = useMemo(() => loadStripe(STRIPE_KEY, { stripeAccount: stripeAccountId }), [stripeAccountId])

	return (
		<Elements stripe={stripePromise}>
			<StripeUI stripeData={stripeData} prevStep={prevStep} handleSave={handleSave} CGV={CGV} />
		</Elements>
	)
}

export default StripeWrapper
