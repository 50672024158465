import React, { lazy, Suspense } from 'react'
import ReactDOM from 'react-dom'
import * as Sentry from '@sentry/react'
import { ErrorBoundary } from 'react-error-boundary'
import { Provider } from 'react-redux'
import store from './store'
import { ConfigProvider, message, Result, Button } from 'antd'
import frFR from 'antd/lib/locale/fr_FR'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import relativeTime from 'dayjs/plugin/relativeTime'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'
import CustomerPayment from './components/CustomerPayment'
import CustomerPaymentGlobal from './components/CustomerPaymentGlobal'
import Signup from './components/Signup'
import Loading from './images/loading.svg'
import ForgotPassword from './components/Login/ForgotPassword'
import CustomerPaymentProduct from './components/CustomerPaymentProduct'
import './index.less'
import 'dayjs/locale/fr'

Sentry.init({
	dsn: 'https://97947eafdef3436cae6bec86e2470c75@o550612.ingest.sentry.io/5674182',
})

dayjs.extend(duration)
dayjs.extend(relativeTime)
dayjs.locale('fr')

const Main = lazy(() => import('./components/Main'))
const OAuthCallback = lazy(() => import('./components/Utils/OAuthCallback'))
const Loader = () => (
	<div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
		<img style={{ objectFit: 'contain', height: '100%' }} src={Loading} />
	</div>
)

const handleReload = () => {
	window.location.reload(false)
}

const ErrorFallback = ({ error }) => (
	<Result
		status="500"
		title="500"
		subTitle="Oups, une erreur est survenue..."
		extra={
			<Button onClick={handleReload} type="primary">
				Recharger la page
			</Button>
		}
	/>
)

const myErrorHandler = (error, info) => {
	message.error('Une erreur est survenue...')
}

ReactDOM.render(
	<ConfigProvider locale={frFR}>
		<ErrorBoundary FallbackComponent={ErrorFallback} onError={myErrorHandler}>
			<Provider store={store}>
				<Suspense fallback={<Loader />}>
					<Router>
						<Switch>
							<Route exact path="/payment/:paymentId" children={<CustomerPayment />} />
							<Route exact path="/product/:productId" children={<CustomerPaymentProduct />} />
							<Route exact path="/public/:userId" children={<CustomerPaymentGlobal />} />
							<Route exact path="/signup" children={<Signup />} />
							<Route exact path="/stripe" children={<Main stripe={true} />} />
							<Route exact path="/oauthcallback" children={<OAuthCallback />} />
							<Route exact path="/forgot-pwd" children={<ForgotPassword />} />
							<Route exact path="/*" children={<Main />} />
						</Switch>
					</Router>
				</Suspense>
			</Provider>
		</ErrorBoundary>
	</ConfigProvider>,
	document.getElementById('root')
)
