import { createSlice } from '@reduxjs/toolkit'
import { firestore } from '../../backend'

export const fetchMapping = () => dispatch => {
	return firestore.collection('mapping').onSnapshot(
		querySnapshot => {
			const docs = querySnapshot.docs.map(doc => ({ ...doc.data(), id: doc.id }))
			dispatch(setMapping(docs))
		},
		error => {
			console.log(error)
		}
	)
}

export const mappingSlice = createSlice({
	name: 'mapping',
	initialState: {
		value: [],
		status: 'idle',
		error: null,
	},
	reducers: {
		setMapping: (state, action) => {
			state.value = action.payload
		},
	},
})

export const getMapping = state => state.mapping.value

export const { setMapping } = mappingSlice.actions

export default mappingSlice.reducer
