import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { firestore } from '../../backend'

export const saveProduct = createAsyncThunk('products/saveProducts', async product => {
	const productId = product.id || firestore.collection('products').doc().id

	if (product.id) {
		return firestore.collection('products').doc(product.id).update(product.data)
	} else {
		return firestore.collection('products').doc(productId).set(product.data)
	}
})

export const productsSlice = createSlice({
	name: 'products',
	initialState: {
		value: [],
		status: 'idle',
		error: null,
	},
	reducers: {
		setProducts: (state, action) => {
			state.value = action.payload
		},
		setProductsStatus: (state, action) => {
			state.status = action.payload
		},
	},
	extraReducers: {
		[saveProduct.pending]: (state, action) => {
			state.status = 'loading'
		},
		[saveProduct.fulfilled]: (state, action) => {
			state.status = 'succeeded'
		},
		[saveProduct.rejected]: (state, action) => {
			state.status = 'failed'
			state.error = action.error.message
		},
	},
})

export const fetchProducts = userId => dispatch => {
	return firestore
		.collection('products')
		.where('userId', '==', userId)
		.onSnapshot(
			querySnapshot => {
				const docs = querySnapshot.docs
					.map(doc => ({ ...doc.data(), id: doc.id }))
					.sort((a, b) => {
						if (a.creationDate > b.creationDate) return -1
						if (a.creationDate < b.creationDate) return 1
						return 0
					})
				dispatch(setProducts(docs))
			},
			error => {
				console.log(error)
			}
		)
}

export const getProducts = state => state.products.value
export const getProductsStatus = state => state.products.status

export const { setProducts, setProductsStatus } = productsSlice.actions

export default productsSlice.reducer
