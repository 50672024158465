import React, { useState, useEffect, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { Button, message, List, Result, Divider, Row, Col } from 'antd'
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import LoadingImage from '../../images/loading.svg'
import Success from '../../images/success.svg'
import Done from '../../images/done.svg'
import { STRIPE_KEY, httpDomain, isTest } from '../../backend'

const CheckoutForm = props => {
	const [loading, setLoading] = useState(false)
	const [isSuccess, setIsSuccess] = useState(false)

	const { clientSecret, CGV } = props

	const stripe = useStripe()
	const elements = useElements()

	const handleSubmit = async event => {
		event.preventDefault()

		setLoading(true)

		if (!stripe || !elements) {
			// Stripe.js has not loaded yet. Make sure to disable
			// form submission until Stripe.js has loaded.
			return
		}

		const cardElement = elements.getElement(CardElement)

		const result = await stripe.confirmCardPayment(clientSecret, {
			payment_method: {
				card: cardElement,
			},
			setup_future_usage: 'off_session',
		})

		setLoading(false)

		if (result.error) {
			console.log('[error]', result.error)
			message.error(result.error.message)
		} else {
			setIsSuccess(true)
		}
	}

	return isSuccess ? (
		<Result icon={<img width="60%" src={Success} />} status="success" title="Opération réussie ! Merci ❤" subTitle="Vous recevrez un email de confirmation directement dans votre boite email." />
	) : (
		<form onSubmit={handleSubmit} style={{ padding: 32 }}>
			<div style={{ marginBottom: 8, fontWeight: 'bold' }}>Votre carte bancaire</div>
			<div style={{ marginBottom: 16 }}>
				<CardElement
					className="ant-input"
					options={{
						iconStyle: 'solid',
						hidePostalCode: true,
						style: {
							base: {
								iconColor: 'lightgray',
								color: 'rgba(0,0,0,0.65)',
								fontWeight: 500,
								fontSize: '18px',
								fontSmoothing: 'antialiased',
								':-webkit-autofill': {
									color: '#fce883',
								},
								'::placeholder': {
									color: 'lightgray',
								},
							},
							invalid: {
								iconColor: '#db4252cc',
								color: '#db4252cc',
							},
						},
					}}
				/>
			</div>
			<div style={{ fontSize: 12, marginBottom: 8 }}>La caution bancaire est une empreinte bancaire. Il ne s'agit pas d'un paiement.</div>
			{CGV && (
				<div style={{ fontSize: 12 }}>
					En cliquant sur Valider, vous acceptez les{' '}
					<a href={CGV} target="_blank">
						Conditions Générales de Ventes
					</a>
				</div>
			)}
			<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
				<Button type="primary" htmlType="submit" disabled={!stripe} loading={loading}>
					Valider
				</Button>
			</div>
		</form>
	)
}

const CustomerPayment = props => {
	const [data, setData] = useState({})
	const [isBlocked, setIsBlocked] = useState(false)

	const { paymentId } = useParams()

	const stripePromise = useMemo(() => loadStripe(STRIPE_KEY, { stripeAccount: data.stripeAccountId }), [data.stripeAccountId])

	useEffect(() => {
		const fetchData = async () => {
			const req = await fetch(`${httpDomain}/getStripeAccountId`, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
				},
				body: JSON.stringify({ data: { paymentId, isTest } }),
			})
			const { result } = await req.json()

			if (result.isBlocked) {
				setIsBlocked(true)
				return message.error('Ce service est temporairement indisponible.')
			}

			setData({ stripeAccountId: result.stripeAccountId, clientSecret: result.clientSecret, details: result.data, status: result.status, CGV: result.cgv_link })
		}
		paymentId && fetchData()
	}, [paymentId])

	return (
		<Row style={{ height: '100vh', overflow: 'scroll', WebkitOverflowScrolling: 'touch', display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: 'rgb(240, 242, 245)', padding: 32 }}>
			<Col sm={{ span: 0 }} lg={{ span: 4 }}></Col>
			<Col lg={{ span: 16 }}>
				<div style={{ backgroundColor: 'white', display: 'flex' }}>
					{data.stripeAccountId ? (
						data.status !== 'waiting' ? (
							<Result icon={<img width="40%" src={Done} />} status="success" title="Votre caution est déjà active !" subTitle="Vous avez déjà renseigné vos informations bancaires." />
						) : (
							<Row style={{ width: '100%' }}>
								<Col xs={{ span: 24 }} lg={{ span: 12 }}>
									<div style={{ flex: 1, padding: 32, borderRight: '1px lightgrey solid' }}>
										<div style={{ fontSize: 20, fontWeight: 700, marginBottom: 32 }}>Détails</div>
										<Divider />
										<List
											dataSource={data.details}
											renderItem={({ title, content }) => (
												<List.Item>
													<List.Item.Meta title={title} description={content || 'Non spécifié'} />
												</List.Item>
											)}
										/>
									</div>
								</Col>
								<Col xs={{ span: 24 }} lg={{ span: 12 }}>
									<div style={{ flex: 1 }}>
										<div className="primary-background-color" style={{ fontSize: 20, fontWeight: 700, color: 'white', padding: 32 }}>
											Enregistrement de votre carte bancaire
										</div>

										<Elements stripe={stripePromise}>
											<CheckoutForm {...props} clientSecret={data.clientSecret} CGV={data.CGV} />
										</Elements>
									</div>
								</Col>
							</Row>
						)
					) : isBlocked ? (
						<div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: 32 }}>
							<div style={{ textAlign: 'center', fontSize: 24, fontWeight: 'bold' }}>Service indisponible.</div>
						</div>
					) : (
						<div style={{ display: 'flex', flexDirection: 'column', width: '100%', padding: 32 }}>
							<div style={{ textAlign: 'center', fontSize: 24, fontWeight: 'bold' }}>Chargement...</div>
							<div style={{ flex: 1, display: 'flex', alignItems: 'center' }}>
								<img src={LoadingImage} style={{ width: '100%', height: 300, objectFit: 'contain' }} />
							</div>
						</div>
					)}
				</div>
			</Col>
			<Col sm={{ span: 0 }} lg={{ span: 4 }}></Col>
		</Row>
	)
}

export default CustomerPayment
