import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { firestore, firebase } from '../../backend'
import { fetchPayments } from './paymentsReducer'
import { fetchCustomers } from './customersReducer'
import { fetchTemplates } from './templatesReducer'
import { fetchMapping } from './mappingReducer'
import { fetchProducts } from './productsReducer'

export const saveActiveUser = createAsyncThunk('activeUser/saveActiveUser', async activeUser => {
	return firestore.collection('users').doc(activeUser.id).update(activeUser.data)
})

export const fetchActiveUser =
	(userId, handleSetListeners, isMaster = true) =>
	dispatch => {
		dispatch(setActiveUserStatus('loading'))
		return firestore
			.collection('users')
			.doc(userId)
			.onSnapshot(
				async doc => {
					dispatch(setActiveUserStatus('idle'))
					if (doc.exists) {
						dispatch(setActiveUser({ id: userId, ...doc.data() }))
						handleSetListeners(dispatch(fetchPayments(userId)))
						handleSetListeners(dispatch(fetchCustomers(userId)))
						handleSetListeners(dispatch(fetchTemplates(userId)))
						handleSetListeners(dispatch(fetchProducts(userId)))
						handleSetListeners(dispatch(fetchMapping()))
						if (isMaster && doc.data().extraUsers?.length > 0) {
							firestore
								.collection('users')
								.where(firebase.firestore.FieldPath.documentId(), 'in', doc.data().extraUsers)
								.get()
								.then(querySnapshot => {
									const extraUsers = querySnapshot.docs.map(dc => ({ ...dc.data(), id: dc.id }))
									dispatch(setExtraUsers([{ id: userId, ...doc.data() }, ...extraUsers]))
								})
						}
					}
				},
				error => {
					console.log(error)
				}
			)
	}

export const activeUserSlice = createSlice({
	name: 'activeUser',
	initialState: {
		value: [],
		extraUsers: [],
		status: 'idle',
		error: null,
	},
	reducers: {
		setActiveUser: (state, action) => {
			state.value = action.payload
		},
		setActiveUserStatus: (state, action) => {
			state.status = action.payload
		},
		setExtraUsers: (state, action) => {
			state.extraUsers = action.payload
		},
	},
	extraReducers: {
		[saveActiveUser.pending]: (state, action) => {
			state.status = 'loading'
		},
		[saveActiveUser.fulfilled]: (state, action) => {
			state.status = 'succeeded'
		},
		[saveActiveUser.rejected]: (state, action) => {
			state.status = 'failed'
			state.error = action.error.message
		},
	},
})

export const getActiveUser = state => state.activeUser.value
export const getActiveUserStatus = state => state.activeUser.status
export const getExtraUsers = state => state.activeUser.extraUsers

export const { setActiveUser, setActiveUserStatus, setExtraUsers } = activeUserSlice.actions

export default activeUserSlice.reducer
