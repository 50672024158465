const handleChange = (type, field, obj, setObj) => value => {
	if (type === 'value') {
		setObj({ ...obj, [field]: value })
	} else if (type === 'input') {
		setObj({ ...obj, [field]: value.target.value })
	} else if (type === 'date') {
		setObj({ ...obj, [field]: value.unix() })
	} else if (type === 'number') {
		setObj({ ...obj, [field]: parseInt(value * 100, 10) })
	} else if (type === 'checkbox') {
		setObj({ ...obj, [field]: value.target.checked })
	}
}

const mappingCurr = {
	eur: '€',
	usd: '$',
	cad: '$',
	chf: 'CHF',
}

export { handleChange, mappingCurr }
