import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import { firestore } from '../../backend'

export const savePayment = createAsyncThunk('payments/savePayment', async payment => {
	const paymentId = payment.id || firestore.collection('payments').doc().id
	if (payment.id) {
		return firestore.collection('payments').doc(payment.id).update(payment.data)
	} else {
		return firestore.collection('payments').doc(paymentId).set(payment.data)
	}
})

export const fetchPayments = userId => dispatch => {
	dispatch(setPaymentsStatus('loading'))
	return firestore
		.collection('payments')
		.where('userId', '==', userId)
		.where('status', '!=', 'deleted')
		.onSnapshot(
			querySnapshot => {
				const docs = querySnapshot.docs
					.map(doc => ({ ...doc.data(), id: doc.id }))
					.sort((a, b) => {
						if (a.creationDate > b.creationDate) return -1
						if (a.creationDate < b.creationDate) return 1
						return 0
					})
				dispatch(setPayments(docs))
				dispatch(setPaymentsStatus('idle'))
			},
			error => {
				console.log(error)
			}
		)
}

export const paymentsSlice = createSlice({
	name: 'payments',
	initialState: {
		value: [],
		status: 'idle',
		error: null,
	},
	reducers: {
		setPayments: (state, action) => {
			state.value = action.payload
		},
		setPaymentsStatus: (state, action) => {
			state.status = action.payload
		},
		resetPayments: state => {
			state.value = []
		},
	},
	extraReducers: {
		[savePayment.pending]: (state, action) => {
			state.status = 'loading'
		},
		[savePayment.fulfilled]: (state, action) => {
			state.status = 'succeeded'
		},
		[savePayment.rejected]: (state, action) => {
			state.status = 'failed'
			state.error = action.error.message
		},
	},
})

export const getPayments = state => state.payments.value
export const getPaymentsStatus = state => state.payments.status

export const { setPayments, setPaymentsStatus, resetPayments } = paymentsSlice.actions

export default paymentsSlice.reducer
