import React, { useState } from 'react'
import { Form, Input, Button, Layout, message, Typography } from 'antd'
import { AiOutlineMail, AiOutlineLock, AiOutlineUser, AiOutlineShop, AiOutlinePhone } from 'react-icons/ai'
import { useHistory } from 'react-router-dom'
import { auth, httpDomain } from '../../backend'
import Stripe from '../../images/stripe.svg'

const { Title, Text } = Typography

const Signup = () => {
	const [loading, setLoading] = useState(false)

	const history = useHistory()

	const onFinish = values => {
		setLoading(true)
		signup(values)
	}

	const signup = values => {
		auth
			.createUserWithEmailAndPassword(values.email.trim(), values.password.trim())
			.then(async userCredential => {
				const user = userCredential.user
				await fetch(`${httpDomain}/createUser`, {
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
					},
					body: JSON.stringify({ data: { id: user.uid, email: values.email.trim(), name: values.name.trim(), business: values.business, phoneNumber: values.phoneNumber } }),
				})
				setLoading(false)
				message.success('Création du compte réussi !')
				history.push('/')
			})
			.catch(error => {
				setLoading(false)
				console.log(error)
				message.error('Une erreur est survenue...')
			})
	}

	return (
		<Layout>
			<div style={{ height: '100vh', display: 'flex' }}>
				<div style={{ flex: 1, backgroundColor: 'white' }}>
					<div style={{ padding: '64px 128px', display: 'flex', flexDirection: 'column', height: '100%', justifyContent: 'space-between', overflow: 'scroll' }}>
						<div className="primary-color " style={{ fontSize: 21, fontWeight: 'bold', display: 'flex', alignItems: 'center' }}>
							<AiOutlineLock style={{ marginRight: 4 }} /> LocShake
						</div>
						<div>
							<Title level={2}>Création de votre compte</Title>
							<Text type="secondary">Créez votre compte dès maintenant et bénéficiez des services de LocShake dans la gestion de vos cautions Stripe.</Text>
							<Form layout="vertical" name="normal_login" initialValues={{ remember: true }} onFinish={onFinish} style={{ marginTop: 32 }}>
								<Form.Item label="Adresse email" name="email" rules={[{ required: true, message: "Où est passé l'email ?" }]}>
									<Input size="large" prefix={<AiOutlineMail />} placeholder="Email" />
								</Form.Item>
								<Form.Item label="Mot de passe" name="password" rules={[{ required: true, message: 'Un soucis avec le mot de passe ?' }]}>
									<Input size="large" prefix={<AiOutlineLock />} type="password" placeholder="Mot de passe" />
								</Form.Item>
								<Form.Item label="Nom de votre entreprise" name="name" rules={[{ required: true, message: 'Vous avez oublié votre nom ?' }]}>
									<Input size="large" prefix={<AiOutlineUser />} placeholder="Nom" />
								</Form.Item>
								<Form.Item label="Téléphone" name="phoneNumber" rules={[{ required: true, message: 'Où est passé le numéro de téléphone ?' }]}>
									<Input size="large" prefix={<AiOutlinePhone />} placeholder="Téléphone" />
								</Form.Item>
								<Form.Item label="Votre activité" name="business" rules={[{ required: true, message: "Vous avez besoin d'aide ?" }]}>
									<Input.TextArea rows={1} size="large" prefix={<AiOutlineShop />} placeholder="Expliquez-nous votre activité en quelques mots" />
								</Form.Item>
								<Form.Item>
									<Button size="large" loading={loading} type="primary" htmlType="submit" style={{ width: '100%', marginTop: 16 }}>
										{loading ? 'Création en cours...' : 'Créer mon compte'}
									</Button>
								</Form.Item>
							</Form>
						</div>
						<div style={{ display: 'flex', justifyContent: 'space-between', fontSize: 12 }}>
							<Text type="secondary">© 2021 Atelier Business Food. Tous droits réservés.</Text>
							{/*<div>
								<Text type="secondary">Conditions générales d'Utilisation</Text>
								<Text style={{ marginLeft: 16 }} type="secondary">
									Politque de confidentialité
								</Text>
							</div>*/}
						</div>
					</div>
				</div>
				<div style={{ flex: 1, padding: 32, display: 'flex', alignItems: 'center' }}>
					<div style={{ width: '100%', height: '60%' }}>
						<img style={{ width: '100%', height: '100%' }} src={Stripe} />
						<Title style={{ textAlign: 'center', marginTop: 32 }} level={4}>
							Vos cautions Stripe, simplement.
						</Title>
					</div>
				</div>
			</div>
		</Layout>
	)
}

export default Signup
