import firebase from 'firebase/app'
import 'firebase/storage'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/app-check'
import 'firebase/functions'

const config = {
	apiKey: 'AIzaSyC0CsiNFuTm03YSz-QbPFhT9HTxG-LpJyE',
	authDomain: 'locshake-6e9f2.firebaseapp.com',
	projectId: 'locshake-6e9f2',
	storageBucket: 'locshake-6e9f2.appspot.com',
	messagingSenderId: '806793904922',
	appId: '1:806793904922:web:0496053ab7d993cb6fa2a3',
}

firebase.initializeApp(config)

const appCheck = firebase.appCheck()
appCheck.activate('6Lf2yqAhAAAAAIAPrlPlpBXj7nHW0sdR0FF13qO0', true)

const firestore = firebase.firestore()
firestore.settings({
	ignoreUndefinedProperties: true,
})

const storage = firebase.storage()
const auth = firebase.auth()
const functions = firebase.functions()

const ACCOUNT_STRIPE_KEY = 'ca_IvtlKB0AFd5OkooO6TvI5WxCGYXBeyYr'
const STRIPE_KEY = 'pk_live_prSXyfHVaVAJPw4inFdXYDMu'
const httpDomain = 'https://us-central1-locshake-6e9f2.cloudfunctions.net'
const isTest = false

// const ACCOUNT_STRIPE_KEY = 'ca_Ivtl6hljv5bQA3PbcUGjNbQlK48gEZ2A' //test
// const STRIPE_KEY = 'pk_test_6QSI5yrkcHNhLudWDJL6wxd8' //test
// //const httpDomain = 'http://localhost:5001/locshake-6e9f2/us-central1' //test
// const httpDomain = 'https://us-central1-locshake-6e9f2.cloudfunctions.net'
// const isTest = true

export { firebase, firestore, storage, auth, ACCOUNT_STRIPE_KEY, STRIPE_KEY, httpDomain, isTest, functions }
